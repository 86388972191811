import React, { useCallback, useEffect, useState } from 'react';
import { Stack, Image, IconButton, Text, Spinner } from '@fluentui/react';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { componentStyles } from './CopilotPanel.styles';
import { appConfig } from '../appConfig';

interface ICopilotPanelProps {
    isOpen: boolean;
    onDismissCopilotPanel: () => void;
}

export const CopilotPanel: React.FunctionComponent<ICopilotPanelProps> = (
    props: ICopilotPanelProps
): JSX.Element => {
    const msal: IMsalContext = useMsal();
    const [scriptLoading, setScriptLoading] = useState<boolean>(false);
    const [scriptLoadFailed, setScriptLoadFailed] = useState<boolean>(false);

    /**
     * Load a script by creating a script element on the page.
     * @param url Url to script to load.
     * @param scriptLoaded Callback to call after script loads.
     * @param scriptLoadError Callback to call if script load fails.
     */
    const loadScript = (url: string, scriptLoaded: () => void, scriptLoadError: () => void) => {
        const script: HTMLScriptElement = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = () => {
            scriptLoaded();
        };
        script.onerror = () => {
            scriptLoadError();
        };
        script.src = url;
        document.getElementsByTagName('head')[0].appendChild(script);
    };

    /**
     * Create copilot web component.
     */
    const createCopilotWebComponent = useCallback(() => {
        const copilotContainer: HTMLElement = document.getElementById('copilot-container')!;

        // If the copilot container already has a child node then the copilot web component has already been created.
        if (copilotContainer.childNodes.length === 0) {
            // Create the copilot web component element.
            const copilotElem: HTMLElement = document.createElement('copilot-component');

            // Set attributes on the element.
            copilotElem.setAttribute(
                'apiBaseUrl',
                appConfig.current.settings.copilotApiBaseUrl
            );
            copilotElem.setAttribute('apiScope', appConfig.current.settings.copilotApiScope);
            copilotElem.setAttribute('copilotAppId', appConfig.current.settings.copilotAppId);
            // Set the height of the copilit component to the view height minus 108px, which accounts for the app header
            // and the panel header height. This will make it take up the full height of the panel.
            copilotElem.setAttribute('height', 'calc(100vh - 84px)');
            copilotElem.setAttribute('showPrivacyLink', 'internal');

            // Add the component to the copilot container div.
            copilotContainer.appendChild(copilotElem);

            // Attach the MSAL instance from Recycling UI to the copilot web component by setting the msalInstance property.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (copilotElem as any).msalInstance = msal.instance;
        }
    }, [msal.instance]);

    /**
     * Load copilot web component script and create the element.
     */
    const loadCopilotWebComponentAndCreate = useCallback(() => {
        // If the copilot-component is not yet defined in the browsers custom element registry.
        if (customElements.get('copilot-component') === undefined) {
            // Load the script to load the web component.
            // The cb is for cache busting to ensure we always get latest.
            setScriptLoading(true);
            loadScript(
                appConfig.current.settings.copilotWebComponentUrl + '?cb=' + Date.now(),
                () => {
                    setScriptLoading(false);
                    createCopilotWebComponent();
                },
                () => {
                    setScriptLoading(false);
                    setScriptLoadFailed(true);
                }
            );
        } else {
            createCopilotWebComponent();
        }
    }, [createCopilotWebComponent]);

    /**
     * Effect that runs when the dialog opens.
     */
    useEffect(() => {
        if (props.isOpen) {
            setTimeout(() => {
                loadCopilotWebComponentAndCreate();
            });
        }
    }, [loadCopilotWebComponentAndCreate, props.isOpen]);

    return (
        <div style={!props.isOpen ? { display: 'none' } : { display: 'block' }}>
            <Stack verticalAlign="start">
                <Stack horizontal className={componentStyles.headingContainer}>
                    <Stack.Item grow>
                        <div className={componentStyles.copilotImageContainer}>
                            <Image src="/images/copilotBlack.svg" width="20px" alt="Copilot" />
                        </div>
                        <Text variant="xLarge">Copilot</Text>
                    </Stack.Item>
                    <Stack.Item align="end">
                        <IconButton
                            iconProps={{ iconName: 'ChromeClose' }}
                            title="Close"
                            ariaLabel="Close"
                            onClick={() => {
                                props.onDismissCopilotPanel();
                            }}
                        />
                    </Stack.Item>
                </Stack>

                {(scriptLoading || scriptLoadFailed) && (
                    <div className={componentStyles.loadingContainer}>
                        {scriptLoading && (
                            <Spinner labelPosition="bottom" label="Loading Copilot..." />
                        )}
                        {scriptLoadFailed && (
                            <Text variant="medium">
                                Failed to load Copilot.
                            </Text>
                        )}
                    </div>
                )}

                <div
                    id="copilot-container"
                    className={componentStyles.copilotContainer}
                />
            </Stack>
        </div>
    );
};
