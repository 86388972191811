import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%'
    } as IStyle,
    copilotContainer: {
        border: '1px solid lightgray'
    } as IStyle,
    headingContainer: {
        borderLeft: '1px solid lightgray',
        paddingLeft: '10px'
    } as IStyle,
    copilotImageContainer: {
        margin: '0px 4px 0px 0px',
        display: 'inline-block',
        verticalAlign: 'sub'
    } as IStyle
});
